export default {
  titleTemplate: "%s | Render Challenge",
  defaultTitle: "Render challenges. Right in your inbox",
  description:
    "The most up-to-date database of 3D art challenges on the Internet! Get notified of new render challenges every Friday.",
  canonical: "https://renderchallenge.com/",
  openGraph: {
    type: "website",
    locale: "en_US",
    title: "Render challenges. Right in your inbox",
    description:
      "The most up-to-date database of 3D art challenges on the Internet! Get notified of new render challenges every Friday.",
    url: `//${process.env.NEXT_PUBLIC_DOMAIN}/`,
    site_name: "Render Challenge",
    images: [
      {
        url: `//${process.env.NEXT_PUBLIC_DOMAIN}/img/social.png`,
        width: 1200,
        height: 628,
        alt: "Render Challenge",
      },
    ],
  },
  twitter: {
    handle: "@renderchallenge",
    site: "@renderchallenge",
    cardType: "summary_large_image",
  },
};
