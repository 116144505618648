import "../styles/globals.css";
import type { AppProps } from "next/app";
import PlausibleProvider from "next-plausible";
import { DefaultSeo } from "next-seo";
import ProgressBar from "nextjs-progressbar";
import { Toaster } from "react-hot-toast";
import { UserProvider } from "@supabase/supabase-auth-helpers/react";
import { supabaseClient } from "@supabase/supabase-auth-helpers/nextjs";

import SEO from "../next-seo.config";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <DefaultSeo {...SEO} />
      <ProgressBar color="#fde046" />
      <Toaster />
      <UserProvider supabaseClient={supabaseClient}>
        <PlausibleProvider
          enabled={
            process.env.NODE_ENV === "production" &&
            Boolean(process.env.PLAUSIBLE_DOMAIN)
          }
          domain={process.env.PLAUSIBLE_DOMAIN!}
          trackOutboundLinks
        >
          <Component {...pageProps} />
        </PlausibleProvider>
      </UserProvider>
    </>
  );
}

export default MyApp;
